<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/cardbin.view.js'
import { useStore } from 'vuex'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const routes = useRoute()

pageTitle.value = t('general.router-cardbin-view')

const store = useStore()
const pageInfo = ref({})

const id = routes.params.id

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const cardBinInfo = await store.dispatch('param/getCardBinListByID', { id })
  pageInfo.value = cardBinInfo
}

getCurrentPageInfo()
</script>
